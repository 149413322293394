<template>
  <floating-card-header :title="$t('shared.assets.shareAsset')" icon="share" class="form__field pt-0 pr-2">
    <template #append>
      <v-dialog v-if="!readonly" width="500">
        <template #activator="{ props: dialogProps }">
          <v-btn v-bind="dialogProps" icon="add" variant="tonal" color="success" />
        </template>

        <template #default="{ isActive }">
          <v-card>
            <v-card-title>{{ $t("shared.assets.shareAsset") }}</v-card-title>

            <v-form @submit.prevent="() => linkAssetToOrganization().then(() => (isActive.value = false))">
              <v-card-text>
                <v-form
                  ref="form"
                  @submit.prevent="
                    () => {
                      isActive.value = false
                    }
                  "
                >
                  <v-autocomplete
                    v-model="selectedOrganizationId"
                    :items="organizations"
                    item-title="description"
                    item-value="_id"
                    :label="$t('assets.field.organization')"
                    required
                  >
                    <template #item="{ item, props: itemProps }">
                      <v-list-item
                        v-bind="itemProps"
                        :disabled="!!assetSharedOrganizations.find((o) => o._id === item.value) || item.value === assetOrganizationId"
                      />
                    </template>
                  </v-autocomplete>
                </v-form>
              </v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  @click="
                    () => {
                      isActive.value = false
                    }
                  "
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn color="primary" type="submit" :disabled="!selectedOrganizationId">{{ $t("save") }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </template>
      </v-dialog>
    </template>
  </floating-card-header>

  <v-list class="w-100 py-0" variant="tonal">
    <v-list-item v-for="organization of assetSharedOrganizations" :key="organization._id" class="rounded mb-1 pr-2">
      <template #default>
        <div class="text-medium-emphasis">{{ $t("shared.assets.sharedWith") }}</div>
        <div class="text-body-1">{{ organization.description }}</div>
      </template>

      <template #append>
        <v-dialog v-if="!readonly" width="600">
          <template #activator="{ props: dialogProps }">
            <v-btn v-bind="dialogProps" variant="text" icon="delete" />
          </template>

          <template #default="{ isActive }">
            <v-card :title="$t('shared.assets.unlinkAsset')">
              <v-card-text>{{ $t("shared.assets.unlinkAssetConfirmation", { organization: organization.description }) }}</v-card-text>

              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  @click="
                    () => {
                      isActive.value = false
                    }
                  "
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  color="error"
                  @click="
                    () =>
                      unlinkAssetFromOrganization(organization._id).then(() => {
                        isActive.value = false
                      })
                  "
                  >{{ $t("remove") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-list-item>

    <v-list-item v-if="assetSharedOrganizations.length === 0" class="rounded mb-1 pr-2">
      <template #title>{{ $t("shared.assets.noSharedOrganizations") }}</template>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
const props = defineProps<{ assetId: string; assetOrganizationId: string; readonly?: boolean }>()
const { assetId, assetOrganizationId, readonly } = toRefs(props)

const sharedStore = useSharedStore()

const selectedOrganizationId = ref<string | null>(null)

const { data: organizations } = useLazyAsyncData(
  `shared-organizations:${assetOrganizationId.value}`,
  () => sharedStore.getSharedOrganizations(),
  { default: () => [] },
)

const { data: assetSharedOrganizations, refresh: refreshSharedOrganizations } = useLazyAsyncData(
  `shared-asset-records:${assetId.value}`,
  () => sharedStore.getAssetSharedOrganizations(assetId.value),
  { default: () => [] as Array<Organization> },
)

const linkAssetToOrganization = async () => {
  if (selectedOrganizationId.value) {
    await sharedStore.linkAssetToOrganization(assetId.value, selectedOrganizationId.value)
    selectedOrganizationId.value = null
    await refreshSharedOrganizations()
  }
}

const unlinkAssetFromOrganization = async (organizationId: string) => {
  await sharedStore.unlinkAssetFromOrganization(assetId.value, organizationId)
  await refreshSharedOrganizations()
}
</script>
